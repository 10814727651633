import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link'
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'



function capercast () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
            <Link href="https://open.spotify.com/show/0ZVOPMPCqXx0lMVqHX6dPs" target="blank" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                  sx = {{height:"200px"}}
                  image =  "./images/capercast.jpg"
                  title = "Capercast Podcast"
                />
                <CardContent>
                  <div className='typo' >
                    Capercast
                  </div>
                  <div className='typo2'> 
                  Co-Host of an entrepreneurship podcast rated Top 10% in Global Podcasts. 
                  Scaled the podcast to 1700+ listeners from 25+ countries.
                  Incubated under the Georgia Tech I2p Program.
                  </div>
                </CardContent>
              </CardActionArea>
              </Link>
              
            </Card>
          </Grid>
          );
}

export default capercast;
