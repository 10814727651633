import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link'
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'



function deliverify () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
            <Link href="https://docs.google.com/presentation/d/1DUc7yOXwrt0KdGrEDOO_yT2OZeZF_6FU/edit#slide=id.p1" target="blank" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                  sx = {{height:"200px"}}
                  image =  "./images/deliverify.png"
                  title = "Deliverify"
                />
                <CardContent>
                  <div className='typo' >
                    Deliverify
                  </div>
                  <div className='typo2'> 
                  Worked alongside Mrianso Software to build a service that reduces intra-city freight transport time by connecting lorry drivers.
                  Attended a startup program at IIT Kanpur by TiE UP and won the second best Business plan.
                  </div>
                </CardContent>
              </CardActionArea>
              </Link>
              
            </Card>
          </Grid>
          );
}

export default deliverify;