import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link'
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'



function capercast () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
            <Link href="https://quadraticvoting.mehulrastogi.com/" target="blank" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                  sx = {{height:"200px"}}
                  image =  "./images/qvoting.png"
                  title = "Quadratic Voting"
                />
                <CardContent>
                  <div className='typo' >
                    Quadratic Voting
                  </div>
                  <div className='typo2'> 
                  Web Application to vote on several choices using the Quadratic Voting Principle. Use it with friends and family to make sure that mathematically sound decisions are made. ~ Nerd 101 ~
                  </div>
                </CardContent>
              </CardActionArea>
              </Link>
              
            </Card>
          </Grid>
          );
}

export default capercast;