import './styles.css'
import Navbar from '../Components/Navbar'
import Center from '../Components/center'
import Capercast from '../Components/CardComponent/capercast'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container'
import Converge from '../Components/CardComponent/converge'
import Powerup from '../Components/CardComponent/powerup'
import StartupNFTs from '../Components/CardComponent/startupnfts'
import GBAbook from '../Components/CardComponent/gbabook'
import StartupIdeas from '../Components/CardComponent/startupideas'
import Caperfy from '../Components/CardComponent/caperfy'
import Mysalutem from '../Components/CardComponent/mysalutem'
import Deliverify from '../Components/CardComponent/deliverify'
import Quadratic from '../Components/CardComponent/quadraticvoting'
import Yelp from '../Components/CardComponent/yelp'
import InfoViz from '../Components/CardComponent/infoviz'
import Psyc from '../Components/CardComponent/psyc'
import Conway from '../Components/CardComponent/conway';
import Author from '../Components/CardComponent/author';
import Curate from '../Components/CardComponent/curate'


function Projects() {
  return (
    <div className="App">
      <Navbar /> 
      <Center />
      <Container maxWidth="lg" className='outercontainer'>
      
      <Grid container spacing={3} marginTop={3} marginBottom={3} >
      <Curate />
      <Author />
      <Conway />
      <Psyc />
      <Capercast />
      <Yelp />
      <InfoViz />
      <Quadratic />   
      <Converge />
      <Powerup />
      <StartupNFTs />
      <GBAbook />
      <StartupIdeas />
      <Caperfy />
      <Mysalutem />
      <Deliverify />

      </Grid> 
      </Container >
      
    </div>
  );
}

export default Projects;
