import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link'
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'

function Author () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
            <Link href="https://www.mehulrastogi.com/SematicInsights.pdf" target="blank" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                   sx = {{height:"200px"}}
                  component="img"
                  image =  "./images/dataviz.png"
                  title = "Data Visualization project CSE 6242"
                />
                <CardContent>
                  <div className='typo' >
                  Semantic Mapping
                  </div>
                  <div className='typo2'> 
                  Revolutionizing academic collaboration with semantic analysis, departing from bibliometrics. Utilizing BM Score, abstract embeddings, and author graphs for precise research exploration. 
                  </div>
                </CardContent>
              </CardActionArea>
              </Link>
              
            </Card>
          </Grid>
          );
}

export default Author;
