import React from 'react';
import './TechyNewspaper.css'; // Create a CSS file for this component and name it "TechyNewspaper.css" to keep the styles separate.
import Navbar from './Components/Navbar'
const TechyNewspaper = () => {

  return (
    <div>
        <Navbar />
      <div className="blog-container">
        <div className="blog">
          <h2>Blog Title 1</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec semper risus.</p>
          <a href="abc.com">Read more</a>
        </div>

        <div className="blog">
          <h2>Blog Title 2</h2>
          <p>Phasellus ullamcorper justo at ante blandit tristique. Nunc a risus ultrices.</p>
          <a href="cabsa.com">Read more</a>
        </div>

        {/* Add more blog entries here */}
      </div>
    </div>
  );
};

export default TechyNewspaper;