import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'

function Conway () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
            <Link to="/projects" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                  sx = {{height:"200px"}}
                  component="img"
                  image =  "./images/conway.gif"
                  title = "Conway Game of Life CS 4510 Gatech"
                />
                <CardContent>
                  <div className='typo' >
                    Conway's Game of Life
                  </div>
                  <div className='typo2'> 
                  Conducted research with friends on why Conway's Game of Life is considered Turing complete. Learned a ton about Celluar Automaton, Universal Turing machines, Gates, and Computers.
                  </div>
                </CardContent>
              </CardActionArea>
              </Link>
              
            </Card>
          </Grid>
          );
}

export default Conway;
