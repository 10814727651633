import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link'
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'



function mysalutem () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
            <Link href="https://docs.google.com/presentation/d/1vFFTo9Q-ZRZ6_NuecEYTkHHY9mzmwCxeVn2krG0EBPA/edit" target="blank" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                  sx = {{height:"200px"}}
                  image =  "./images/mysalutem.png"
                  title = "My Salutem"
                />
                <CardContent>
                  <div className='typo' >
                    My Salutem
                  </div>
                  <div className='typo2'> 
                  As part of MIT LaunchX at Northwestern University, I built a 24/7 ECG Monitoring wearable that sends custom notifications and alerts to family and friends in case of adverse medical situations.
                  </div>
                </CardContent>
              </CardActionArea>
              </Link>
              
            </Card>
          </Grid>
          );
}

export default mysalutem;