import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import '../styles/navbar.css';
import { Link } from 'react-router-dom';

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#ffffff" }}>
        <Toolbar className="items">
          <div className="typographymehul">
          <a href="/">
            Mehul Rastogi
            <span className="dot">.</span>
            </a>
          </div>
            <div className ="navBarRightItems"> 
          <div className="navitems">
          <a href="https://food.mehulrastogi.com" target="_blank" rel="noopener noreferrer">Food</a>
          <Link to="/projects">Projects</Link>
          </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
