import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Projects from './Projects'
import TechyNewspaper from "./Blogs";
import HomePage from "./Home";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blogs" element={<TechyNewspaper />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </Router>
  );
}