import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link'
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'



function infoviz () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
            <Link href="https://roadfatalities.mehulrastogi.com/" target="_blank" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                  sx = {{height:"200px"}}
                  image =  "./images/infoviz.png"
                  title = "Info Viz Road Fatalities"
                />
                <CardContent>
                  <div className='typo' >
                    Fatalities Vizualization
                  </div>
                  <div className='typo2'> 
                  Built an interactive visualization to analyze the Transportation Fatalities database. It provides the user ability to get details on demand and analyze the different types of accidents taking over the years. 
                  </div>
                </CardContent>
              </CardActionArea>
              </Link>
              
            </Card>
          </Grid>
          );
}

export default infoviz;
