import React from "react";
import '../styles/center.css'

function center() {
  return (
    <div className="outer">
      <div className="Heading1"> 
      <span className="Mehul"> Mehul Rastogi</span><span className="centerdot">.</span> 
      </div>
     <div className="Heading2">
      <span className="bsp"> Building, Shipping, and Scaling Products</span> 
      </div>

    </div>
  );
}

export default center;