import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'



function gbabook () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
            <Link to="/projects" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                  sx = {{height:"200px"}}
                  image =  "./images/gbabook.png"
                  title = "GBA Book to Championship"
                />
                <CardContent>
                  <div className='typo' >
                    BooktoChampionship
                  </div>
                  <div className='typo2'> 
                  An interactive game built on C to be played on the Game Boy Advance (GBA) emulator. 
                  Using dependencies like gcc-arm-none-eabi, mednafen, cs2110-gba-linker-script, and nin10kit.
                  </div>
                </CardContent>
              </CardActionArea>
              </Link>
              
            </Card>
          </Grid>
          );
}

export default gbabook;
